import { Link } from "gatsby";
import React from "react";

const LoginSignupButtons = () => {
  return (
    <div>
      <Link
        to={`https://databowl.typeform.com/partner-hype`}
        className="btn btn-sm nav-item ml-lg-2 m-0 btn-secondary py-2 px-3"
      >
        Join Us
      </Link>
    </div>
  );
};

export default LoginSignupButtons;
