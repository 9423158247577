import React, { useState } from "react";

import LoginSignupButtons from "./Authentication/LoginSignupButtons";
import logoWhite from "./../../img/logo-light.svg";
import logoDark from "./../../img/logo-dark.svg";
// import HamburgerMenu from "react-hamburger-menu";
import site from "../../../_data/settings/marketTheme.json";
import { Link } from "gatsby";

export const NavBar = function ({
  isAuthenticated,
  alert,
  social,
  contact,
  user,
  meta,
  isLoading,
}) {
  const [open, setOpen] = useState(false);

  return (
    <div className={`${alert.alertEnable ? "with-alert" : ""}`}>
      <div
        className={`navbar-fixed-top ${
          open || site.NavDark ? site.NavBGDark : site.NavBGLight
        } ${alert.alertEnable ? "with-alert" : ""}`}
      >
        {alert.alertEnable && (
          <div className={`headeralert bg-${alert.alertBackground} py-3`}>
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <span className="headeralert-text">{alert.alertMessage}</span>
                <Link
                  onClick={() => setOpen(false)}
                  cover={`true`}
                  direction="right"
                  bg="#1F1A3C"
                  to={alert.alertLink}
                  className={
                    "btn-" + alert.alertButtonColor + " btn btn-sm ml-2"
                  }
                >
                  {alert.alertTitle}
                </Link>
              </div>
            </div>
          </div>
        )}

        <nav
          className={`navbar-expand-lg ${
            open || site.NavDark ? site.NavBGDark : site.NavBGLight
          }`}
          id="mainnavbar"
        >
          <div className="navbar  py-3 py-md-4 py-lg-3">
            <div className="navbar-brand">
              <Link
                onClick={() => setOpen(false)}
                cover={`true`}
                direction="right"
                bg="#1F1A3C"
                to="/"
                className="navbar-item"
              >
                <figure className="image">
                  {site.NavDark === true || open ? (
                    <img
                      src={logoWhite}
                      alt={site.BrandName}
                      className="brand py-1 py-lg-2"
                    />
                  ) : (
                    <>
                      <img
                        src={logoDark}
                        alt={site.BrandName}
                        className="brand dark py-1 py-lg-2"
                      />
                      <img
                        src={logoWhite}
                        alt={site.BrandName}
                        className="brand light py-1 py-lg-2"
                      />
                    </>
                  )}
                </figure>
              </Link>
            </div>

            {/* <div className="navbar-toggler">
              <HamburgerMenu
                isOpen={open}
                menuClicked={() => setOpen(!open)}
                width={24}
                height={16}
                strokeWidth={2.5}
                rotate={0}
                color={`
                ${
                  site.NavDark === true
                    ? open
                      ? "white"
                      : "white"
                    : open
                    ? "white"
                    : "black"
                }
              
            `}
                borderRadius={100}
                animationDuration={0.5}
              />
            </div> */}
            <div
              className={`  ${
                site.NavDark === true ? `navbar-dark` : `navbar-light`
              }  ml-auto flex-nowrap ${open && `show`} `}
              id="partnerhypeNavbar"
            >
              <ul id="menu-main-menu" className="navbar-nav ml-auto">
                {/* <li className="nav-item">
                  <Link
                    onClick={() => setOpen(false)}
                    className="nav-link"
                    to="/lead-certificate/"
                  >
                    {site.nav.leadCertificates}
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#howitworks">
                    How it Works
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/#pricing">
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#contact">
                    Contact
                  </a>
                </li> */}
                <li className="ml-lg-2 nav-item">
                  <LoginSignupButtons
                    size="sm"
                    className="nav-item"
                    color="secondary"
                  />
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default NavBar;
