import React from "react";
import { Link } from "gatsby";
import logoWhite from "../../../img/logo-light.svg";
import site from "./../../../../_data/settings/marketTheme.json";

export const FooterMain = ({
  socials_title,
  facebook,
  twitter,
  instagram,
  linkedin,
  footer_menu_1_title,
  footer_menu_1_links,
  footer_menu_2_title,
  footer_menu_2_links,
  footer_contact_title,
  footer_address,
  footer_email,
  footer_phone,
  footer_company_vat,
  footer_copyright,
}) => {
  return (
    <footer className="page-footer font-small bg-primary">
      {/* <section className="bg-secondary">
        <div className="container-fluid">
          <div className="row contained-xl py-4 justify-content-center align-items-center">
            {socials_title && (
              <div className="col-md-6 col-lg-4 text-center text-md-left mb-4 mb-md-0">
                <h4 className="h5 mb-0">{socials_title}</h4>
              </div>
            )}
            <div
              className={`col-md-6 col-lg-6 text-center text-md-right ${
                !socials_title ? "offset-lg-4" : ""
              }`}
            >
              <a
                href={linkedin}
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                <i className="fab fa-linkedin-in white-text"></i>
                <span className="sr-only">LinkedIn</span>
              </a>

              <a
                href={twitter}
                rel="noreferrer"
                target="_blank"
                className="mr-4"
              >
                <i className="fab fa-twitter white-text"></i>
                <span className="sr-only">Twitter</span>
              </a>
              <a
                href={facebook}
                rel="noreferrer"
                target="_blank"
                className=""
              >
                <i className="fab fa-facebook-f white-text"></i>
                <span className="sr-only">Facebook</span>
              </a>

            </div>
          </div>
        </div>
      </section> */}

      <section
        className="container-fluid text-center text-md-left pt-5"
        data-aos="fade-in"
      >
        <div className="row contained-xl justify-content-center mt-3 py-4">
          <div className="col-lg-10">
            <div className="row justify-content-center">
              <div className="col-md-3 col-lg-4 mb-4">
                <img
                  src={logoWhite}
                  alt={site.BrandName}
                  className="img-fluid brand mb-4"
                />
              </div>

              {footer_menu_1_links ? (
                <div className="col-6 col-md-2 col-lg-2 mb-4 offset-lg-1">
                  {footer_menu_1_title ? (
                    <>
                      <div className="h6 font-weight-bold font-family-display">
                        {footer_menu_1_title}
                      </div>
                      <hr className="border col-1 border-secondary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    </>
                  ) : (
                    <div className="mt-lg-5"></div>
                  )}
                  {footer_menu_1_links &&
                    footer_menu_1_links.map(function (items, i) {
                      return (
                        <p key={"footer_menu_1_link" + i}>
                          <Link
                            cover={`true`}
                            direction="right"
                            bg="#1F1A3C"
                            to={items.url}
                          >
                            {items.title}
                          </Link>
                        </p>
                      );
                    })}
                </div>
              ) : (
                <div className="col-lg-3"></div>
              )}

              {footer_menu_2_links && (
                <div className="col-6 col-md-3 col-lg-2 mb-4">
                  {footer_menu_2_title ? (
                    <>
                      <div className="h6 font-weight-bold font-family-display">
                        {footer_menu_2_title}
                      </div>
                      <hr className="border col-1 border-secondary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    </>
                  ) : (
                    <div className="mt-lg-5"></div>
                  )}
                  {footer_menu_2_links &&
                    footer_menu_2_links.map(function (items, i) {
                      return (
                        <p key={"footer_menu_2_link" + i}>
                          <Link
                            cover={`true`}
                            direction="right"
                            bg="#1F1A3C"
                            to={items.url}
                          >
                            {items.title}
                          </Link>
                        </p>
                      );
                    })}
                </div>
              )}

              <div className="col-md-4 col-lg-3 mt-4 mt-md-0 mb-md-0 mb-4 text-center text-md-right ">
                <div className="h6 font-weight-bold font-family-display">
                  {footer_contact_title}
                </div>
                <hr className="border col-1 border-secondary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                <div className="small">
                  <p>
                    <i className="fas fa-map-marker-alt mr-2"></i>
                    {footer_address}
                  </p>
                  <p>
                    <i className="fas fa-envelope mr-2"></i>
                    <a href={"mailto:" + footer_email}>{footer_email}</a>
                  </p>
                  <p>
                    <i className="fas fa-phone mr-2"></i>
                    <a href={"tel:" + footer_phone}>{footer_phone}</a>
                  </p>
                  <p>
                    <i className="fas fa-print mr-2"></i> {footer_company_vat}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center mb-5 mb-lg-0 pt-lg-3 py-5 pb-lg-4 small">
          © {new Date().getFullYear()}
          {footer_copyright}
          <br />
          Partner Hype is a trading name of Horizon Landings Ltd
        </div>
      </section>
    </footer>
  );
};

export default FooterMain;
